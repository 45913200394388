import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="June 2020" subnav="release-notes">
      <div id="June2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          It's hot. And so humid. There's probably a lot of sweat all over this
          release. Sorry about that.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.3.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.3.0"
            />

            <div className={styles.changesList}>
              <Fixed>
                The sizing for xsmall{' '}
                <Link href="/components/notifications/note/design">notes</Link>{' '}
                and{' '}
                <Link href="/components/action-list/design">action lists</Link>.
                (Thanks <Link href="https://github.com/cz">Craig!</Link>)
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>Xsmall note to go with the component fixes above.</Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
